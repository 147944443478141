import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { post, put } from "../../../utility/fetch";
import toast, { Toaster } from "react-hot-toast";
const AddSeasonalItem = ({
  closeModal,
  fetchData,
  updateData,
  deductionSource,
}) => {
  const [formData, setFormData] = useState({
    description: updateData?.seasonalItem || "",
    deductionSourceId: "",
    percentage: updateData?.percentage || "",
    amount: updateData?.amount || "",
    period: updateData?.period || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addSeasonalBonus = async (payload) => {
    try {
      const res = await post("/SeasonalBonus", payload);
      if (res.status === "success") toast.success("Item Added");
      else toast.error(res.message);
    } catch (err) {
      toast.error(err);
    }
  };

  const updateSeasonalBonus = async (payload) => {
    try {
      const item = {
        ...payload,
        id: updateData?.id,
      };

      const res = await put("/SeasonalBonus", item);
      if (res.status === "success") toast.success("Item updated");
      else toast.error(res.message);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateData) {
      await updateSeasonalBonus(formData);
    } else {
      await addSeasonalBonus(formData);
    }

    fetchData();
    closeModal();
  };

  useEffect(() => {
    const selectedDeductionSource = deductionSource.find(
      (source) => source.label === updateData?.calculatedFrom
    );

    if (selectedDeductionSource) {
      setFormData((prev) => ({
        ...prev,
        deductionSourceId: selectedDeductionSource.value,
      }));
    }
  }, []);

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="p-20">
          {updateData ? (
            <h2>Update Seasonal Item</h2>
          ) : (
            <h2>Add Seasonal Item</h2>
          )}

          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="w-100 m-t-10">
                <Select
                  options={deductionSource}
                  required
                  placeholder="Select Deduction Source"
                  value={deductionSource?.find(
                    (source) => source.value === formData.deductionSourceId
                  )}
                  onChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      deductionSourceId: value?.value,
                    }))
                  }
                />
              </div>

              <div className="m-t-10 w-100">
                {" "}
                <label>Description</label>
                <input
                  className="pay-input "
                  type="text"
                  name="description"
                  required
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>

              <div className="m-t-10">
                {" "}
                <label>Percentage</label>
                <input
                  className="pay-input w-100"
                  step="any"
                  min={0}
                  type="number"
                  name="percentage"
                  required
                  value={formData.percentage}
                  onChange={handleChange}
                />
              </div>

              <div className="m-t-10">
                {" "}
                <label>Period</label>
                <input
                  className="pay-input w-100 "
                  type="text"
                  name="period"
                  value={formData.period}
                  onChange={handleChange}
                />
              </div>

              <button
                type="button"
                className="btn btn-large w-100 m-t-10"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSeasonalItem;
