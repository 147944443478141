import tune from '../../assets/svg/tune.svg'
import plus from '../../assets/svg/add.svg'
import Select from 'react-select'
import TextInput from '../inputs/TextInput'
import TextareaInput from '../inputs/TextareaInput'
import ReportTable from '../layouts/ReportTable'
import ApprovalJourney from '../layouts/ApprovalJourney'
import { useContext, useState } from 'react'
import ComputationTable from '../layouts/ComputationTable'
import { MyContext } from '../../contexts'
import ComputationModal from '../layouts/ComputationModal'
import { months, stripCommas } from '../../utility/general'
import { get, post, put } from '../../utility/fetch'
import { get as getTreasury } from '../../utility/treasuryFetch'
import { AiOutlineSend } from "react-icons/ai";
import { GrReturn } from "react-icons/gr";
import { IoIosReturnLeft } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from "react-html-table-to-excel";




const PayrollPreview = (props) => {
    const [showComputationTable, setShowComputationTable] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [modalIndex, setModalIndex] = useState(false);
    const [userName, setUserName] = useState("");
    const [approverComment, setApproverComment] = useState("");
    const [prevData, setPrevData] = useState([])
    const [tableId] = useState("table-to-xls")
    const [bulkUploadArray, setBulkUploadArray] = useState([]);
    const [updateValue, setUpdateValue] = useState("");
    const [updateColumn, setUpdateColumn] = useState("")
    const [allOn, setAllOn] = useState(false);
    const [isMgt, setIsMgt] = useState(false);

    const [salaryVouchers, setSalaryVouchers] = useState([]);



    const { payrollData, additionalColumns, setAdditionalColumns, setPostComp, setPayrollData, isEdit, setIsEdit } = useContext(MyContext)


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const isApprover = queryParams.get('isA');

    const history = useHistory();

    const closeModal = () => {
        setShowModal(false);
    }


    useEffect(() => {
        getPrevious();
        handleNetPay();
        getSalaryVouchers();
    }, [])


    const getSalaryVouchers = async () => {
        let res = await getTreasury('/Voucher/Form/Pending');
        console.log(res)
        if (res.status === 'success') {
            setSalaryVouchers(res?.data?.map(voucher => ({ label: voucher?.pvNo, value: voucher.id })));
        }
    }

    const handleSelectedSalaryVoucher = (value) => {
        setPayrollData({ ...payrollData, voucherId: Number(value.value) });
    }

    const handleNetPay = () => {
        if (Array.isArray(payrollData.staffPayroll)) {
            // Create a copy of the state object
            const updatedState = { ...payrollData };

            // Create a copy of the staffPayroll array
            const updatedStaffPayroll = [...updatedState?.staffPayroll];

            // Update the specific element using the index
            const updatedStaffPayrollTemp = updatedStaffPayroll?.map((item) => {

                return {
                    ...item,
                    netPay: parseFloat(parseFloat(stripCommas(
                        (Number(item?.baseSalary) +
                            Number(item?.totalAllowances) +
                            Number(item?.arrears) +
                            Number(item?.otherBonus) +
                            Number(item?.ltg) +
                            Number(item?.thirteenthMonth) -
                            (Number(item?.tax) +
                                Number(item?.pensionContribution) +
                                Number(item?.otherDeductions) +
                                Number(item?.healthInsuranceDeductions) +
                                Number(item?.cooperativeDeductions))
                        )) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item.amount, 0)) -
                        parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item.amount, 0))).toFixed(2)),
                }
            })

            // Update the staffPayroll array in the copied state object
            updatedState.staffPayroll = updatedStaffPayrollTemp;

            // Set the state with the updated object
            setPayrollData(updatedState);
        }
    }

    const handleRadio = (e) => {
        console.log(e.target.value);
    }

    const submitApproverPayroll = async () => {
        if (!approverComment) {
            toast.error("Please enter an approver comment")
            return
        }
        let payload = {
            payrollId: payrollData?.id,
            isMgt: isMgt,
            approverComment: approverComment
        }

        try {
            let res = await post("/AuthApproval/submit-approval", payload)
            if (res.data === "Success") {
                toast.success(res?.message)
                history.push('/payroll/payroll-approver')
            }
        }
        catch (err) {
            console.log(err);
            toast.error(err?.message);
        }

    }

    const updatePayroll = async () => {
        console.log("Updating")
        // Calculate the sum of all netPay values
        const totalNetPay = payrollData.staffPayroll.reduce((sum, item) => sum + item?.netPay, 0);

        // Calculate the sum of all netPay values
        const totalGrossPay = payrollData.staffPayroll.reduce((sum, item) => sum + item?.baseSalary + item?.totalAllowances, 0);

        // Calculate the sum of all netPay values
        const totalDeductions = payrollData.staffPayroll.reduce((sum, item) => sum + item.tax + item?.pensionContribution + item?.otherDeductions, 0);

        let tempStaffPayroll = payrollData.staffPayroll.map((staffPayroll) => {
            return {
                id: staffPayroll.id,
                employeeId: staffPayroll.employeeId,
                baseSalary: staffPayroll.baseSalary,
                totalAllowances: staffPayroll.totalAllowances,
                tax: staffPayroll.tax,
                pensionContribution: staffPayroll.pensionContribution,
                otherDeductions: staffPayroll.otherDeductions,
                netPay: staffPayroll.netPay,
                notes: staffPayroll.notes
            };
        })

        let payload = {
            ...payrollData,
            staffPayroll: tempStaffPayroll,
            totalGrossPayment: parseFloat(totalGrossPay.toFixed(2)),
            totalDeductions: parseFloat(totalDeductions.toFixed(2)),
            overallNetPayment: parseFloat(totalNetPay.toFixed(2))
        }



        // try {
        //     let res = await post("/payroll", payload);
        //     if (res.status === "success") {
        //         toast.success(res.message)
        //         history.push("/payroll");
        //     }
        //     else {
        //         toast(res.message)
        //         console.log(res);
        //     }
        // } catch (error) {
        //     toast.error(error.message);
        // }
    }

    const sendForReview = async () => {
        if (!approverComment) {
            toast.error("Please enter an approver comment")
            return
        }
        let payload = {
            payrollId: payrollData?.id,
            isMgt: isMgt,
            approverComment: approverComment
        }
        try {
            let res = await post("/AuthApproval/submit-review", payload)
            if (res.code === 200) {
                toast.success(res?.message)
                history.push('/payroll/payroll-approver')
            }
            else {
                toast(res.message)
                console.log(res);
            }
        }
        catch (err) {
            console.log(err);
            toast.error(err?.message);
        }
    }


    const getPrevious = async () => {
        if (!payrollData?.mdaId || payrollData.mdaId === 0) {
            history.push('/payroll')
            return;
        }
        const res = await get(`/Payroll/Previous/${payrollData?.mdaId}`)
        if (res?.status === "success") {
            setPrevData(res?.data);
        }

        console.log(res);
    }


    const submitPayroll = async () => {
        console.log(payrollData)

        // Calculate the sum of all netPay values
        const totalNetPay = payrollData?.staffPayroll?.reduce((sum, item) => sum + parseFloat(item?.netPay), 0);

        console.log(totalNetPay)

        // Calculate the sum of all netPay values
        const totalGrossPay = payrollData?.staffPayroll?.reduce((sum, item) => sum + item?.baseSalary + item?.totalAllowances, 0);

        // Calculate the sum of all netPay values
        const totalDeductions = payrollData?.staffPayroll?.reduce((sum, item) => sum + item?.tax + item?.pensionContribution + item?.otherDeductions + item?.cooperativeDeductions - item?.thirteenthMonth, 0);

        let tempStaffPayroll = payrollData?.staffPayroll?.map((staffPayroll) => {
            return {
                
                employeeId: staffPayroll.employeeId ,
                staffName: staffPayroll.staffName,
                salarytructure: staffPayroll.salarytructure,
                grade: staffPayroll.grade,
                staffId: staffPayroll.staffId,
                mdaId: staffPayroll.mdaId,
                baseSalary: staffPayroll.baseSalary,
                totalAllowances: staffPayroll.totalAllowances,
                tax: staffPayroll.tax,
                healthInsuranceDeductions: staffPayroll.healthInsuranceDeductions,
                pensionContribution: staffPayroll.pensionContribution,
                otherDeductions: staffPayroll.otherDeductions || 0,
                arrears: staffPayroll.arrears,
                ltg: staffPayroll.ltg,
                staffDeductions: staffPayroll.staffDeductions,
                bonus: staffPayroll.bonus,
                otherStaffBonus: staffPayroll.otherStaffBonus,
                thirteenthMonth: staffPayroll.thirteenthMonth || 0,
                cooperativeDeductions: staffPayroll.cooperativeDeductions || 0,
                netPay: parseFloat(staffPayroll.netPay),
                salaryAccountNumber: staffPayroll.salaryaccountNumber,
                salaryDomiciledBank: staffPayroll.salaryDomiciledBank,
                notes: staffPayroll.notes

                // id: staffPayroll.id,
                // employeeId: staffPayroll.employeeId,
                // thirteenthMonth: staffPayroll.thirteenthMonth || 0,
                // cooperativeDeductions: staffPayroll.cooperativeDeductions || 0,
                // baseSalary: staffPayroll.baseSalary,
                // comment: staffPayroll.comment,
                // totalAllowances: staffPayroll.totalAllowances,
                // tax: staffPayroll.tax,
                // pensionContribution: staffPayroll.pensionContribution,
                // otherDeductions: staffPayroll.otherDeductions || 0,
                // netPay: parseFloat(staffPayroll.netPay),
                // notes: staffPayroll.notes
            };
        })

        if (isEdit) {
            console.log(payrollData?.staffPayroll)
            let payload = {
                id: payrollData?.id,
                mdaId: payrollData?.mdaId,
                title: payrollData?.title,
                month: payrollData?.month,
                year: payrollData?.year,
                voucherId: payrollData?.voucherId,
                comment: payrollData?.initiatorsComment,
                totalGrossPayment: parseFloat(totalGrossPay.toFixed(2)),
                totalDeductions: parseFloat(totalDeductions.toFixed(2)),
                overallNetPayment: parseFloat(totalNetPay.toFixed(2)),
                staffPayroll: payrollData?.staffPayroll
            }

            // console.log(payload);
            try {
                let res = await put("/payroll", payload);
                console.log(res)
                if (res.status === "success") {
                    toast.success(res.message);
                    setIsEdit(false);
                    setPostComp(false);
                    setPayrollData(
                        {
                            mdaId: 0,
                            title: "",
                            month: null,
                            year: null,
                            comment: "",
                            totalGrossPayment: 0,
                            totalDeductions: 0,
                            overallNetPayment: 0,
                            thirteenthMonth: "",
                            cooperativeDeductions: "",
                            otherDeductions: "",
                            staffPayroll: [

                            ]
                        }
                    )
                    history.push("/payroll/payroll-initiation");
                }
                else {
                    toast(res.message)
                    console.log(res);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
            }
        }
        else {
            console.log(totalGrossPay)
            console.log(totalGrossPay)
            console.log(totalNetPay)
            let payload = {
                ...payrollData,
                otherDeductions: parseFloat(payrollData.otherDeductions || 0),
                cooperativeDeductions: parseFloat(payrollData.cooperativeDeductions || 0),
                thirteenthMonth: parseFloat(payrollData.thirteenthMonth || 0),
                staffPayroll: tempStaffPayroll,
                totalGrossPayment: parseFloat(totalGrossPay.toFixed(2)),
                totalDeductions: parseFloat(totalDeductions.toFixed(2)),
                overallNetPayment: parseFloat(totalNetPay.toFixed(2))
            }
            delete payload?.mda;
            delete payload?.monthName;
            delete payload?.yearName;

            try {
                let res = await post("/payroll", payload);
                console.log(res)
                if (res.status === "success") {
                    toast.success(res.message);
                    setPostComp(false);
                    setPayrollData(
                        {
                            mdaId: 0,
                            title: "",
                            month: null,
                            year: null,
                            comment: "",
                            totalGrossPayment: 0,
                            totalDeductions: 0,
                            overallNetPayment: 0,
                            thirteenthMonth: "",
                            cooperativeDeductions: "",
                            otherDeductions: "",
                            staffPayroll: [

                            ]
                        }
                    )
                    history.push("/payroll/payroll-initiation");
                }
                else {
                    toast(res.message)
                    console.log(res);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    }

    console.log(payrollData)


    return (
        <div className="w-100">
            <div className="flex space-between br-b-5 p-20 m-b-20">
                <div className="flex flex-v-center">
                    <img className='m-r-10' src={tune} alt="" />
                    <p className='dash-text'>{months[payrollData?.month - 1]?.label} Payroll Preview</p>
                </div>
                <div className="flex w-50 ">
                    <TextInput disabled value={payrollData?.mda} className="pay-inputs m-r-20" placeholder="No of Employees" />
                    {isApprover !== "true" && <Link className="btn m-r-10 m-l-10" to="/payroll/payroll-computation">
                        <button className='btn'>
                            Return to edit mode
                        </button>
                    </Link>}
                    {showComputationTable && <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn w-30 m-r-10 m-l-10"
                        table="table-to-xls"
                        filename={`Payroll for the month of ${months[payrollData?.month - 1]?.label}`}
                        sheet="tablexls"
                        buttonText="Download" />}
                </div>

            </div>

            <div className="flex flex-v-end space-between">
                <div className='w-100 flex-48 m-r-20'>
                    <div className='m-b-20'>
                        <TextInput disabled value={payrollData?.title} className="pay-input" placeholder="Enter Payroll Title" />
                    </div>
                    <div className='flex m-b-20 space-between'>
                        <TextInput disabled value={months[payrollData?.month - 1]?.label} className="pay-input" placeholder="Month" />
                        <TextInput disabled value={payrollData?.year} className="pay-input" placeholder="Year" />

                        <TextInput disabled value={payrollData?.noOfEmployees || payrollData?.staffPayroll?.length || payrollData?.staffPayrollResponse?.length} className="pay-input" placeholder="No of Employees" />
                    </div>
                    <div className=''>
                        <TextareaInput disabled value={payrollData?.comment || payrollData?.initiatorsComment} className="w-100" placeholder="Opening Comments" />
                    </div>
                </div>
                <div className="flex w-100 flex-48">
                    <ReportTable prevData={prevData} />
                </div>
            </div>

            <div className='flex flex-v-center m-t-20 m-b-40 pointer' onClick={() => setShowComputationTable((prev) => !prev)}>
                <img src={plus} alt="" />
                <p className='m-l-10 '><strong>Show Payroll Details</strong></p>

            </div>
            {
                showComputationTable && <div>
                    <ComputationTable bulkUploadArray={bulkUploadArray} setBulkUploadArray={setBulkUploadArray} additionalColumns={additionalColumns} tableId={tableId} showModal={showModal} setModalIndex={setModalIndex} setUserName={setUserName} setShowModal={setShowModal} tableData={payrollData?.staffPayroll} payrollData={payrollData} setPayrollData={setPayrollData} showOthers={true} show13th={true} showCoop={true} review={true} />
                </div>
            }
            {showModal && <ComputationModal userName={userName} closeModal={closeModal} modalIndex={modalIndex} setPayrollData={setPayrollData} payrollData={payrollData} review={true} />}

            {
                isApprover === "false" && <div className='w-50 m-b-20 m-t-20'>
                    <p>Select Salary Voucher:</p>
                    <Select options={salaryVouchers} onChange={handleSelectedSalaryVoucher} />
                </div>
            }
            <div className='p-20 m-t-20 white-bg w-75 view-det-pane'>
                <p className='note-text m-b-10'>
                    Initiator’s Note/Comment:
                </p>
                <p>
                    {payrollData?.comment || payrollData.initiatorsComment}
                </p>
            </div>

            <div>

                {(isApprover === "true" || payrollData?.statusDetails === "Review") && <ApprovalJourney approvers={payrollData?.payrollApproverResponse} />}
                {isApprover === "true" && <div className='m-t-40 w-75'>
                    <p className='note-text m-b-10'>Input your Comment</p>
                    <TextareaInput onChange={(e) => setApproverComment(e.target.value)} className="w-100 m-b-10" placeholder="Enter your Comments" />

                    {payrollData?.journeyStateId === 5 && <div>
                        <b className='m-t-20'>Select Payment Office:</b>
                        <div className='flex flex-v-center m-t-10'>
                            <div className="flex m-r-10">
                                <input onClick={handleRadio} type="radio" id="MAC" name="payment_office" value="MAC" />
                                <label for="MAC">Management Account</label><br />
                            </div>
                            <div className="flex flex-v-center">
                                <input onClick={handleRadio} type="radio" id="TCA" name="payment_office" value="TCA" />
                                <label for="TCA">Treasury Cash Account</label>
                            </div>
                        </div>
                    </div>}


                    <div className='m-t-20 flex '>
                        <button onClick={submitApproverPayroll} className="btn m-r-10 w-100"><div className='flex flex-v-center flex-h-center' ><AiOutlineSend color='white' size={20} /> <p className='m-l-10'>Approve</p></div></button>
                        <button onClick={sendForReview} className="btn w-100"><div className='flex flex-v-center flex-h-center'><IoIosReturnLeft color='white' size={20} /> <p className='m-l-10'>Send Back for Review</p></div></button>
                    </div>


                </div>}

            </div>


            {isApprover === "false" && <div className='w-100 m-t-20'>

                <button onClick={submitPayroll} className='btn w-100 btn-large'>{isEdit ? "Update Payroll" : "Save Payroll"}</button>
            </div>}

        </div>
    )
}

export default PayrollPreview