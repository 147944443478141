/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import { RiBarChartFill, RiUserSettingsLine, RiUserSettingsFill, RiStackFill } from 'react-icons/ri';
import { BiEnvelope } from 'react-icons/bi';
import { HiOutlineUserAdd } from 'react-icons/hi';
import { CgUserList } from 'react-icons/cg';


const sidebarMenu = () =>{
  let menuList;
  if(localStorage.getItem('accessLevelId') === "1" ){
    return menuList = [
      { title: 'Dashboard', href: '/payroll', icon: <RiBarChartFill className="icon" /> },
      { title: 'Initiate Monthly Payroll', href: '/payroll/payroll-initiation', icon: <BiEnvelope className="icon" /> } ,
      // { title: 'Supervisor Organogram', href: '/admin-portal/supervisor-organogram', icon: <RiStackFill className="icon" /> },
      { title: 'Payroll Approver', href: '/payroll/payroll-approver', icon: <RiUserSettingsLine className="icon" /> },
      // { title: 'Salary Template Manager', href: '/payroll/settings/salary-template-manager', icon: <RiStackFill className="icon" /> },
      // { title: 'Deduction Manager', href: '/payroll/settings/deduction-manager', icon: <RiStackFill className="icon" /> },
      // { title: 'Seasonal Item/Bonus', href: '/payroll/settings/seasonal-item', icon: <RiStackFill className="icon" /> },
      // { title: 'Salary Review', href: '/payroll/settings/salary-review-manager', icon: <RiStackFill className="icon" /> },
    
    
    ]
  }
  else if(localStorage.getItem('accessLevelId') === "9" ){
    return menuList = [
      { title: 'Dashboard', href: '/payroll', icon: <RiBarChartFill className="icon" /> },
      { title: 'Initiate Monthly Payroll', href: '/payroll/payroll-initiation', icon: <BiEnvelope className="icon" /> } ,
    ]
    }
  else{
    return menuList = [
      { title: 'Dashboard', href: '/payroll', icon: <RiBarChartFill className="icon" /> },
      // { title: 'Initiate Monthly Payroll', href: '/payroll/payroll-initiation', icon: <BiEnvelope className="icon" /> } ,
      // { title: 'Supervisor Organogram', href: '/admin-portal/supervisor-organogram', icon: <RiStackFill className="icon" /> },
      { title: 'Payroll Approver', href: '/payroll/payroll-approver', icon: <RiUserSettingsLine className="icon" /> },
    
      { title: 'Salary Template Manager', href: '/payroll/settings/salary-template-manager', icon: <RiStackFill className="icon" /> },
      { title: 'Deduction Manager', href: '/payroll/settings/deduction-manager', icon: <RiStackFill className="icon" /> },
      { title: 'Seasonal Item/Bonus', href: '/payroll/settings/seasonal-item', icon: <RiStackFill className="icon" /> },
      { title: 'Salary Review', href: '/payroll/settings/salary-review-manager', icon: <RiStackFill className="icon" /> },
    
    
    ]
  }
}

export default sidebarMenu;
