import tune from "../../../assets/svg/tune.svg";
import upload from "../../../assets/svg/upload.svg";
import TextareaInput from "../../inputs/TextareaInput";
import TextInput from "../../inputs/TextInput";
import Select from "react-select";
const InitiateReviewRequest = () => {
  return (
    <div className="w-100 flex">
      <div className="w-60 p-20">
        <div className="flex flex-v-center space-between ">
          <div className="flex space-between">
            <div className="flex flex-v-center m-t-20">
              <img className="m-r-10" src={tune} alt="" />
              <span className="dash-text">Initiate Request</span>
            </div>
          </div>
          <div className="w-50">
            <div className="flex space-between m-b-20">
              <div className="w-100">
                <Select placeholder="Select Type" />
              </div>
            </div>
          </div>
        </div>
        <TextInput className="pay-input m-t-20" placeholder="Subject" />
        <TextInput className="pay-input m-t-20" placeholder="From" />
        <TextInput className="pay-input m-t-20" placeholder="To" />
        <div className="flex flex-v-center">
          {" "}
          <TextInput
            className="pay-input w-75 m-t-20"
            placeholder="Through"
          />{" "}
          <button
            type="button"
            className="pointer   secondary-btn w-25 m-t-10 m-l-10"
          >
            {" "}
            + Add More
          </button>
        </div>

        <TextareaInput className="w-100 m-t-20 " />

        <div className="flex flex-v-center m-t-20">
          <p>Urgency Status</p>
          <button className="add-button pointer p-20 m-l-10">High</button>
          <button className="add-button pointer p-20 m-l-10">Urgent</button>
          <button className="add-button pointer p-20 m-l-10">
            Super Urgent
          </button>
        </div>

        <div className="w-50 m-t-20">
          <div className="m-t-20">
            <p>Attach Documents</p>
            <button
              type="button"
              className="btn rounded-pill p-20 w-100 m-t-20 flex flex-v-center space-between"
            >
              {" "}
              Upload Documents
              <img src={upload} alt="" />
            </button>
            <div className="w-100 m-t-20">
              <Select placeholder="Select From M-File" />
            </div>
          </div>
        </div>

        <div className="w-100 m-t-20">
          <button type="button" className="secondary-btn w-100 p-20 ">
            Save Draft
          </button>
          <button type="button" className="btn w-100 p-20 m-t-20">
            Submit Request
          </button>
        </div>
      </div>

      <div className="w-40">
        {" "}
        <div className="w-100 m-t-20">
          <div>
            <button type="button" className="secondary-btn w-100 p-20 ">
              Save Draft
            </button>
            <button type="button" className="add-button w-100 p-20 m-t-20">
              Oghogho Winifred Ogbekhilu
            </button>
          </div>
          <div className="w-100 m-t-20">
            <div className="m-t-20">
              <button type="button" className="secondary-btn w-100 p-20 ">
                Sent Memo
              </button>
              <button type="button" className="add-button w-100 p-20 m-t-20">
                Oghogho Winifred Ogbekhilu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiateReviewRequest;
