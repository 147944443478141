/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';

// import { decodeToken } from '../../utility/auth';

const LocationDetails = ({ details = {} }) => {
  const [username, setUsername] = useState(localStorage.getItem('name'));
  const [role, setRole] = useState(localStorage.getItem('role'));

//   useEffect(() => {
//     const r = localStorage.getItem('r');
//     let fn = localStorage.getItem('fn');
//     let ln = localStorage.getItem('ln');

//     r && setRole(decodeToken('r').r);
//     if (fn && ln) {
//       fn = decodeToken('fn').fn;
//       ln = decodeToken('ln').ln;
//       setUsername(`${fn} ${ln}`);
//     }
//   }, []);

  return (
    <div className="greeting">
      <p className="m-b-5">
        Good Day
      </p>
      <h3 className="m-b-5">{username || 'Osagie Osaigbovo'}</h3>
      <p className="" style={{ fontSize: '0.75rem' }}>
        {role || 'Chief Community Manager'}
        ,
      </p>
    </div>
  );
};

export default LocationDetails;
