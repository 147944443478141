import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import roundedNote from "../../assets/svg/Ellipse.svg"
import TextareaInput from '../inputs/TextareaInput';
import { useLocation } from 'react-router-dom';
import TextInput from '../inputs/TextInput';

const ComputationModal = ({ closeModal, userName,  isEdit, modalIndex, review, setPayrollData, payrollData }) => {

    const [note, setNote] = useState("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const isApprover = queryParams.get('isA');

    const submitForm = (note) => {
        console.log('Work in Progress...');

        // Create a copy of the state object
        const updatedState = { ...payrollData };

        // Create a copy of the staffPayroll array
        const updatedStaffPayroll = [...updatedState?.staffPayroll];

        // Update the specific element using the index
        updatedStaffPayroll[modalIndex] = { ...updatedStaffPayroll[modalIndex], notes: note };

        // Update the staffPayroll array in the copied state object
        updatedState.staffPayroll = updatedStaffPayroll;

        // Set the state with the updated object
        setPayrollData(updatedState);

    };

    console.log(payrollData)

    return (
        <div className="overlay">
            <IoMdClose className="close-btn pointer" onClick={closeModal} />
            <div className="modal-box max-w-400">
                <div className="content">
                    <div className='flex flex-v-center flex-h-center flex-direction-v w-100 m-b-20'>
                        <div className='m-b-20'>
                            <img src={roundedNote} alt="" />
                        </div>
                        <p >Additional note to {userName && userName}</p>
                    </div>


                    <TextareaInput value={payrollData?.staffPayroll[modalIndex]?.notes} disabled = {isApprover || review === true} onChange={(e) => submitForm(e?.target?.value)} className="w-100 m-b-10" rows={3} />
                    {!review && <button
                        type="button"
                        className="btn btn-large w-100"
                        onClick={closeModal}
                    >
                        Add Comment
                    </button>}
                </div>
            </div>
        </div>
    );
};

export default ComputationModal;
