import React, { useEffect, useState } from 'react';
import Table from '../layouts/Table';
import LocationDetails from '../layouts/Greeting';
import Card from '../layouts/Card';
import tune from "../../assets/svg/tune.svg"
import FilterHeader from '../layouts/FilterHeader';
import ApproverTable from '../layouts/ApproverTable';
import { get } from '../../utility/fetch';
import { useContext } from 'react';
import { MyContext } from '../../contexts';
import { AiOutlineDelete, AiOutlineSend } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { getStatus } from '../../utility/general';
import { Tooltip } from 'react-tooltip';


const PayrollApprover = ({ history }) => {
    const { isApprover, setIsApprover, setPayrollData } = useContext(MyContext)
    const [tableData, setTableData] = useState([])
    const [title, setTitle] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'EDSG - Payroll Approver';
        setTitle('Payroll Approver');
        getPayrollApprovals()
        setIsApprover(true);
    }, [])

    const getPayrollApprovals = async () => {


        let res;

        if(localStorage.getItem('accessLevelId') === "1"){
            res = await get(`/Payroll/Paginated/DFA?PageNumber=1&PageSize=50`)
        }
        else{
              res = await get(`/Payroll/Paginated?PageNumber=1&PageSize=50`)
        }
        
      
        console.log(res)
        if (res.status === "success") {
            setTableData(res?.data?.items)
        }
        else {
            console.log(res)
        }

    }

    const previewpayroll = (data) => {
        console.log(data)
        setPayrollData({
            ...data,
            staffPayroll: data?.staffPayrollResponse
        });
        history.push('/payroll/payroll-preview/?isA=true')
    }


    return (
        <div className='w-100'>
            <LocationDetails />
            <div className='flex flex-v-center m-t-20'>
                <img className='m-r-10' src={tune} alt="" /> <span className='dash-text'>MDA/Agency Payroll Status Log</span>
            </div>

            <div>
                <FilterHeader />
            </div>

            <div className="w-100 m-t-80">
                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>Agency</th>
                            <th>Initiator</th>
                            <th>Payroll Title</th>
                            <th>Approval Status</th>
                            <th>Last Treated</th>

                        </tr>
                    </thead>
                    <tbody className="white-bg">
                        {tableData.map((item, index) => (
                            <tr data-tooltip-id={`tooltip-${index}`} onClick={() => previewpayroll(item)} className='clickable-row' key={item?.id}>
                                <td >{item?.mda}</td>
                                <td>{item?.initiator}</td>
                                <td>{item?.title}</td>
                                {/* <td>
                                    {getStatus(item)}
                                </td> */}

                                <td >
                                    <div className="flex flex-v-center h-100">
                                        <div
                                            className={`rounded-pill m-r-10 ${item.statusDetails === "ApprovalCompleted"
                                                ? "green-bg"
                                                : item.statusDetails === "Rejected"
                                                    ? "red-bg"
                                                    : item.statusDetails === "Pending"
                                                        ? "yellow-bg"
                                                        : "gray-bg"
                                                }`}
                                        ></div>{" "}
                                        {getStatus(item)}
                                    </div>

                                </td>

                                <td>
                                    {new Date(item?.modifiedDate).toUTCString()}
                                </td>
                                <Tooltip
                                    id={`tooltip-${index}`} 
                                    place="bottom"
                                    content="Action"
                                />
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default PayrollApprover;
