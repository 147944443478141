const Card = (props) =>{
    return(
        <div className="w-80 m-10">
            <div className="dash-card w-100">
                <div>{props?.title || "N/A"} </div>
                <h2 className="p-t-40 "><b>{props?.count || 0}</b></h2>

            </div>
        </div>
    )
}

export default Card;