import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { post } from "../../../utility/fetch";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const AddSalaryTemplate = ({ closeModal, fetchData, types }) => {
  const [formData, setFormData] = useState({
    level: "",
    step: "",
    annualSalary: "",
    mealSubsidy: "",
    entertainment: "",
    amount: "",
    domesticServant: "",
    ltg: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await post("/SalaryStructure", formData);

      if (res.status === "success") {
        toast.success("Salary Structure Added");
        closeModal();

        // Introduce a 5-second delay before reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <div className="overlay">
      <Toaster />
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="p-20">
          <h2>Add Salary Structure</h2>
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="w-100 m-t-10">
                <label>Type</label>
                <Select
                  options={types}
                  required
                  placeholder="Select Type"
                  onChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      typeId: value?.value,
                    }))
                  }
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Level</label>
                <input
                  min={0}
                  className="pay-input"
                  type="number"
                  name="level"
                  required
                  onChange={handleChange}
                  value={formData.level}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Step</label>
                <input
                  min={0}
                  className="pay-input"
                  required
                  type="number"
                  name="step"
                  onChange={handleChange}
                  value={formData.step}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Annual Salary</label>
                <input
                  min={0}
                  className="pay-input"
                  step="any"
                  type="number"
                  name="annualSalary"
                  required
                  onChange={handleChange}
                  value={formData.annualSalary}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Meal Subsidy</label>
                <input
                  min={0}
                  className="pay-input"
                  type="number"
                  step="any"
                  name="mealSubsidy"
                  required
                  onChange={handleChange}
                  value={formData.mealSubsidy}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Entertainment</label>
                <input
                  min={0}
                  className="pay-input"
                  type="number"
                  step="any"
                  name="entertainment"
                  required
                  onChange={handleChange}
                  value={formData.entertainment}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>Domestic Servant</label>
                <input
                  min={0}
                  className="pay-input"
                  type="number"
                  step="any"
                  name="domesticServant"
                  required
                  onChange={handleChange}
                  value={formData.domesticServant}
                />
              </div>

              <div className="w-100 m-t-10">
                <label>LTG</label>
                <input
                  min={0}
                  className="pay-input"
                  type="number"
                  step="any"
                  name="ltg"
                  required
                  onChange={handleChange}
                  value={formData.ltg}
                />
              </div>

              <button type="submit" className="btn btn-large w-100 m-t-10">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSalaryTemplate;
