import { useState } from "react";
import notes from "../../assets/svg/notes.svg";
import trash from "../../assets/svg/trash.svg";
import { del, put } from "../../utility/fetch";
import AddDeductionItem from "./modals/AddDeductionItem";
import ConfirmationModal from "./modals/ConfirmationModal";
import toast, { Toaster } from "react-hot-toast";

const DeductionTable = ({
  tableData,
  fetchData,
  deductionSource,
  isNew,
  isRequest,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [confirmDel, setConfirmDel] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleConfirm = () => {
    setConfirmDel(!confirmDel);
  };
  const setItem = (item) => {
    setUpdateData(item);
    toggleModal();
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };

  const setId = (id) => {
    setSelectedId(id);
    toggleConfirm();
  };

  const submit = async (id) => {
    const res = await put("/Deduction/submit", [id]);
    toast.success(res.message);
    fetchData();
    console.log(res);
  };
  const removeItem = async (id) => {
    try {
      const res = await del(`/Deduction/${id}`, [id]);
      toggleConfirm();
      if (res.status === "success") toast.success("Deduction deleted");
      else toast.error(res.message);
      console.log(res);
      fetchData();
    } catch (err) {
      toast.error(err);
      console.log(err);
    }
  };

  return (
    <div>
      <Toaster />
      <div className="w-100 ">
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th>S/N</th>
              <th>Deduction Items</th>
              <th>%Deduction</th>
              <th>Deduction From</th>
              <th>Initiated By</th>
              <th>updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="white-bg view-det-pane">
            {tableData && tableData.length > 0
              ? tableData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.deductionItems}</td>
                    <td>{item.percentage}</td>
                    <td>{item.deductedFrom}</td>
                    <td>{item.initiatedBy}</td>
                    <td>
                      {item.dateModified && formatDate(item.dateModified)}
                    </td>
                    {isRequest ? (
                      <td>
                        <a
                          href={`/payroll/settings/salary-review-manager/approve-deduction-request/${item.id}`}
                        >
                          <button className="btn">View Item</button>
                        </a>
                      </td>
                    ) : (
                      <td className="flex flex-v-center items-center">
                        <img
                          src={trash}
                          alt=""
                          className="m-r-20 pointer"
                          onClick={() => setId(item.id)}
                        />
                        {!isNew && (
                          <a
                            href={`/payroll/settings/deduction-manager/initiate-add-request/${item.id}`}
                          >
                            <img
                              src={notes}
                              alt=""
                              className="pointer"
                              // onClick={() => setItem(item)}
                            />
                          </a>
                        )}
                      </td>
                    )}

                    {item?.statusDesc === "Unasigned" && isNew && (
                      <td>
                        <button className="btn" onClick={() => submit(item.id)}>
                          Confirm
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              : null}
          </tbody>
        </table>

        {isModalOpen && (
          <AddDeductionItem
            closeModal={toggleModal}
            updateData={updateData}
            fetchData={fetchData}
            deductionSource={deductionSource}
          />
        )}

        {confirmDel && (
          <ConfirmationModal
            cancel={() => toggleConfirm()}
            confirm={() => removeItem(selectedId)}
            message={"Are You sure You want to delete?"}
          />
        )}
      </div>
    </div>
  );
};

export default DeductionTable;
