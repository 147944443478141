import { useContext, useState } from "react";
import plus from "../../assets/svg/add.svg"
import { MyContext } from "../../contexts";
import { numberWithCommas } from "../../utility/general";

const ReportTable = ({ prevData }) => {

    const [showStat, setShowStat] = useState(false)
    const { payrollData, setPayrollData, isEdit, setIsEdit } = useContext(MyContext)

    return (
        <div className="w-100 none-flex-item">
            <table className="report-table">
                <thead className="border-top-none">
                    <tr className="border-top-none">
                        <th></th>
                        {prevData?.map((data) => (
                            <th align="right">{`${data?.month + 1}/${data?.year}`}</th>
                        ))
                        }

                    </tr>
                </thead>
                <tbody>
                    <tr className="m-b-20" >
                        <td>Total Gross Payment</td>
                        {prevData?.map((data) => (
                            <td align="right">{`₦${numberWithCommas(data?.totalGrossPayment)}`}</td>
                        ))
                        }

                    </tr>

                    <tr className="m-b-20">
                        <td>Total Deductions</td>
                        {prevData?.map((data) => (
                            <td align="right">{`₦${numberWithCommas(data?.totalDeductions)}`}</td>
                        ))
                        }

                    </tr>

                    <tr className="m-b-20">
                        <td>Overall Net Payment</td>
                        {prevData?.map((data) => (
                            <td align="right">{`₦${numberWithCommas(data?.overallNetPayment)}`}</td>
                        ))
                        }

                    </tr>

                </tbody>
            </table>

            <div>
                {/* <div onClick={() => setShowStat((prev) => !prev)} className="flex space-between flex-v-center p-20 view-det-pane pointer hovers m-b-10">
                    <p>View statistics of Officers by GL</p>
                    <img src={plus} alt="" />
                </div> */}

                {showStat &&
                    <div className="white-bg p-20 view-det-pane absolute-item w-100">
                        <div className="p-10">Total Employees (87)</div>
                        <div className="p-10"> Grade Level (GL) 18 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 17 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 16 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 15 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 14 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 13 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 12 - 5 </div>
                        <div className="p-10"> Grade Level (GL) 11 - 5 </div>

                    </div>
                }
            </div>



        </div>
    )
}

export default ReportTable;