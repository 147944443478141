import tune from "../../../../assets/svg/tune.svg";
import upload from "../../../../assets/svg/upload.svg";
import TextareaInput from "../../../inputs/TextareaInput";
import TextInput from "../../../inputs/TextInput";
import Select from "react-select";
import { useState } from "react";
import { get, post, put } from "../../../../utility/fetch";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import SeasonalItemTable from "../../../layouts/SeasonalItemTable";

const InitiateSeasonalBonus = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    subject: "",
    comment: "",
    deductionSourceId: "", // Initialize with an empty string
    // deductionId: 0,
    description: "",
    percentage: "",
    period: "",
    amount: "",
  });
  const [approvers, setApprovers] = useState([
    {
      approverId: 0,
      priority: 0,
    },
  ]);
  const [bonus, setBonus] = useState([]);
  const [selectedMda, setSelectedMda] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deductionSource, setDeductionSource] = useState([]);
  const [mdaUsers, setMdaUsers] = useState([]);
  const [selectedMdaUserIds, setSelectedMdaUserIds] = useState([]);
  const [invalid, setInvalid] = useState(false);

  const getBonuSources = async () => {
    try {
      const res = await get("/DeductionSource");
      setDeductionSource(
        res.data?.map((item) => ({
          label: item.description,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getValues = async () => {
    if (id !== "0") {
      try {
        const res = await get(`/Bonus/Approved/${id}`);
        console.log(res.data);

        setFormData({
          subject: "",
          comment: "",
          deductionSourceId: "", // Initialize with an empty string
          deductionId: id,
          description: res.data.seasonalItem,
          percentage: res.data.percentage,
          amount: res.data.amount,
          period: res.data.period,
        });
      } catch (err) {
        setInvalid(true);
        console.log(err);
      }
    }
  };

  // const getMdas = async () => {
  //   const res = await get("/ModuleUsers");
  //   setMdas(
  //     res.data?.map((item) => ({
  //       label: item.name,
  //       value: item.id,
  //     }))
  //   );
  // };

  const submit = async () => {
    const payload = [
      {
        ...formData,
        approvers: approvers, // Send it as an array, even if it has only one element
        approvalMemoUrl: ["string"],
      },
    ];

    console.log(payload);
    try {
      const res = await post("/Bonus", payload);
      toast.success(res.message);
      getBonus();
      console.log(res);
      resetForm();
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const getBonus = async () => {
    const res = await get("/Bonus");
    console.log(res);
    setBonus(res.data);
  };

  const getMdaUsers = async () => {
    const res = await get("/ModuleUsers");
    setMdaUsers(
      res.data?.map((item) => ({
        label: item.user,
        value: item.userId,
      }))
    );
    console.log(res);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      subject: "",
      comment: "",
      deductionSourceId: "",
      description: "",
      percentage: "",
      period: "",
      deductedFrom: "",
      amount: "",
    });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getMdaUsers();
    getValues();
  }, []);

  useEffect(() => {
    getBonuSources();
    getBonus();
  }, []);

  return (
    <div className="w-100">
      {invalid ? (
        <h1>Record not found</h1>
      ) : (
        <div>
          <div className="w-100 flex">
            <div className="w-60 p-20">
              <div className="flex flex-v-center space-between ">
                <div className="flex space-between">
                  <div className="flex flex-v-center m-t-20">
                    <img className="m-r-10" src={tune} alt="" />
                    <span className="dash-text">Initiate Request</span>
                  </div>
                </div>
                <div className="w-50">
                  <div className="flex space-between m-b-20"></div>
                </div>
              </div>
              <TextInput
                className="pay-input m-t-20"
                placeholder="Subject"
                id="subject"
                name="subject"
                onChange={handleChange}
                value={formData.subject}
              />
              <div className="m-t-20 flex">
                <div className="w-50">
                  <Select
                    options={mdaUsers}
                    isMulti
                    value={mdaUsers.filter((user) =>
                      selectedMdaUserIds.includes(user.value)
                    )}
                    onChange={(values) => {
                      const userIds = values.map((value) => value.value);
                      setSelectedMdaUserIds(userIds);
                      setApprovers(
                        userIds.map((userId, index) => ({
                          approverId: userId,
                          priority: index + 1,
                        }))
                      );
                    }}
                    placeholder="Select Users"
                  />
                </div>
              </div>
              <div className="flex space-between m-b-20">
                <div className="w-75 m-r-10"></div>
              </div>

              <TextareaInput
                className="w-100 m-t-20"
                placeholder="Comment"
                onChange={handleChange}
                name="comment"
                id="comment"
                value={formData.comment}
              />

              {/* <div className="w-50 m-t-20">
                <div className="m-t-20">
                  <p>Attach Documents</p>
                  <button
                    type="button"
                    className="btn rounded-pill p-20 w-100 m-t-20 flex flex-v-center space-between"
                  >
                    {" "}
                    Upload Documents
                    <img src={upload} alt="" />
                  </button>
                  <div className="w-100 m-t-20">
                    <Select placeholder="Select From M-File" />
                  </div>
                </div>
              </div> */}

              <div className="w-100 m-t-20">
                {/* <button type="button" className="secondary-btn w-100 p-20 ">
                  Save Draft
                </button> */}
                <button
                  type="button"
                  className="btn w-100 p-20 m-t-20"
                  onClick={submit}
                >
                  Submit Request
                </button>
              </div>
            </div>

            <div className="w-40">
              <div className="m-t-20">
                <div className="max-w-400">
                  <div className="content m-t-20">
                    <form>
                      <div className="">
                        <div className="m-t-10">
                          {" "}
                          <label>Description</label>
                          <input
                            className="pay-input w-100"
                            type="text"
                            name="description"
                            required
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Deduction Percentage</label>
                          <input
                            className="pay-input w-100"
                            step="any"
                            min={0}
                            type="number"
                            name="percentage"
                            required
                            value={formData.percentage}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Period</label>
                          <input
                            className="pay-input w-100"
                            type="text"
                            name="period"
                            value={formData.period}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Amount</label>
                          <input
                            className="pay-input w-100"
                            type="number"
                            step="any"
                            min={0}
                            name="amount"
                            required
                            value={formData.amount}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-100 m-t-20">
                          <Select
                            options={deductionSource}
                            required
                            placeholder="Select Deduction Source"
                            onChange={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                deductionSourceId: value?.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SeasonalItemTable
            tableData={bonus}
            fetchData={getBonus}
            deductionSource={deductionSource}
            isNew={true}
          />
        </div>
      )}
    </div>
  );
};

export default InitiateSeasonalBonus;
