import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { post, put } from "../../../utility/fetch";
import toast, { Toaster } from "react-hot-toast";

const AddDeductionItem = ({
  closeModal,
  fetchData,
  updateData,
  deductionSource,
}) => {
  const [formData, setFormData] = useState({
    deductionSourceId: "", // Initialize with an empty string
    description: updateData?.deductionItems || "",
    percentage: updateData?.percentage || "",
    accountName: updateData?.accountName || "",
    bankAccountNo: updateData?.bankAccountNo || "",
    bankName: updateData?.bankName || "",
    deductedFrom: updateData?.deductedFrom || "",
    amount: "",
  });

  const [salaries, setSalaries] = useState([
    { value: "Basic Salary", label: "Basic Salary" },
    { value: "Annual Salary", label: "Annual Salary" },
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addDeduction = async (payload) => {
    try {
      const res = await post("/deduction", payload);
      if (res.status === "success") toast.success("Deduction Added");
      else toast.error(res.message);
    } catch (err) {
      toast.error(err);
    }
  };

  const updateDeduction = async (payload) => {
    try {
      const item = {
        ...payload,
        id: updateData?.id,
      };

      const res = await put("/deduction", item);
      if (res.status === "success") toast.success("Deduction updated");
      else toast.error(res.message);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateData) {
      await updateDeduction(formData);
    } else {
      await addDeduction(formData);
    }

    fetchData();
    closeModal();
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      deductionFrom: selectedOption.value,
    });
  };

  useEffect(() => {
    // Fetch deductionSourceId based on deductedFrom
    const selectedDeductionSource = deductionSource.find(
      (source) => source.label === formData.deductedFrom
    );

    if (selectedDeductionSource) {
      setFormData((prev) => ({
        ...prev,
        deductionSourceId: selectedDeductionSource.value,
      }));
    }
  }, []);

  return (
    <div className="overlay">
      {" "}
      <Toaster />
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          {updateData ? (
            <h2>Update Deduction Item</h2>
          ) : (
            <h2>Add Deduction Item</h2>
          )}

          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="m-t-10">
                {" "}
                <label>Description</label>
                <input
                  className="pay-input w-100"
                  type="text"
                  name="description"
                  required
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>

              <div className="m-t-10">
                {" "}
                <label>Deduction Percentage</label>
                <input
                  className="pay-input w-100"
                  step="any"
                  min={0}
                  type="number"
                  name="percentage"
                  required
                  value={formData.percentage}
                  onChange={handleChange}
                />
              </div>

              <div className="m-t-10">
                {" "}
                <label>Account Name</label>
                <input
                  className="pay-input w-100 "
                  type="text"
                  name="accountName"
                  value={formData.accountName}
                  onChange={handleChange}
                />
              </div>
              <div className="m-t-10">
                {" "}
                <label>Account Number</label>
                <input
                  className="pay-input w-100"
                  type="number"
                  name="bankAccountNo"
                  value={formData.bankAccountNo}
                  onChange={handleChange}
                />
              </div>
              <div className="m-t-10">
                {" "}
                <label>Bank Name</label>
                <input
                  className="pay-input w-100"
                  type="text"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                />
              </div>

              <div className="m-t-10">
                {" "}
                <label>Amount</label>
                <input
                  className="pay-input w-100"
                  type="number"
                  step="any"
                  min={0}
                  name="amount"
                  required
                  value={formData.amount}
                  onChange={handleChange}
                />
              </div>
              <div className="w-100 m-t-20">
                <Select
                  options={deductionSource}
                  required
                  placeholder="Select Deduction Source"
                  value={deductionSource?.find(
                    (source) => source.value === formData.deductionSourceId
                  )}
                  onChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      deductionSourceId: value?.value,
                    }))
                  }
                />
              </div>

              <button type="submit" className="btn btn-large w-100 m-t-10">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDeductionItem;
