import Select from "react-select";
import tune from "../../assets/svg/tune.svg";
import TextareaInput from "../inputs/TextareaInput";
import TextInput from "../inputs/TextInput";
import { useContext, useEffect, useState } from "react";
import { del, get, post, put } from "../../utility/fetch";
import { getStatus, months, years } from "../../utility/general";
import { MyContext } from "../../contexts";
import { Link } from "react-router-dom";
import { AiOutlineDelete, AiOutlineSend } from 'react-icons/ai';
import Pagination from "rc-pagination/lib/Pagination";
import { MdEdit } from 'react-icons/md';
import toast from "react-hot-toast";
import { deleteAlert, submitAlert } from "../../utility/alert";
// import {useNavigate} from 'react-router-dom';


const PayrollInitiation = (props) => {
    const [mdas, setMdas] = useState([]);
    const [selectedMda, setSelectedMda] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [tableData, setTableData] = useState([])
    const [monthValue, setMonthValue] = useState([])
    const [yearValue, setYearValue] = useState([])
    const [paginationLength, setPaginationLength] = useState(0);
    const { payrollData, setPayrollData, isEdit, setIsEdit } = useContext(MyContext);

    useEffect(() => {
        getMdas();
        getPayroll();
    }, [])

    // const navigate = useNavigate();

    // useEffect(()=>{
    //     let selectMonth = months.find((month) => (month.value === data?.month))
    //     console.log(selectMonth)
    //     setMonthValue(selectMonth);

    //     let selectYear = years.find((year) => (year.value === data?.year))
    //     console.log(selectYear)
    //     setYearValue(selectYear);
    // }, [])


    const getPayroll = async (pageNumber = 1) => {
        try {
            const res = await get(`/Payroll/Paginated?PageNumber=${pageNumber}&PageSize=50`)
            if (res?.status === "success") {
                setTableData(res?.data?.items);
                setPaginationLength(res?.data?.totalItemCount);
            }

        } catch (error) {
            toast.error(error.message)
        }

    }


    // const getPayroll = async () => {
    //     const res = await get("/Payroll/Paginated?PageNumber=1&PageSize=10")
    //     console.log(res)
    //     setTableData(res?.data?.items)
    // }



    const getMdas = async () => {
        const res = await get("/mdas");
        setMdas(res.data?.map((item) => (
            {
                label: item.name,
                value: item.id
            }
        )))
    }

    const submitFromSaved = async (data) => {
        try {
            let res = await put(`/Payroll/Submit/${data?.id}`)
            if (res?.status === "success") {
                getPayroll();
                toast.success(res.message);
            }
            else{
                toast.error(res.message)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }


    const handlePagination = (pageNumber) => {
        getPayroll(pageNumber);
    }


    const editSaved = async (data) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        console.log(data);
        setPayrollData({
            ...data,
            staffPayroll: data.staffPayrollResponse
        });

        let selectMonth = months.find((month) => (month.value === data?.month))
        setMonthValue(selectMonth);
        setPayrollData((prev)=> ({
            ...prev,
            monthName: selectMonth.label
        }));

        let selectYear = years.find((year) => (year.value === data?.year))
        setYearValue(selectYear);

        setIsEdit(true);
    }

    const validateInitiate = () => {
        let payload = {
            mdaId: payrollData.mdaId,
            title: payrollData.title,
            comment: payrollData.comment || payrollData.initiatorsComment,
            month: payrollData.month,
            year: payrollData.year,
        }

        console.log(payload);

        if (!payload.mdaId) {
            toast.error("Please select MDA");
            return;
        }
        else if (!payload.title) {
            toast.error("Please enter title");
            return;
        }
        else if (!payload.comment ) {
            toast.error("Please enter comment");
            return;
        }
        else if (!payload.month) {
            toast.error("Please select month");
            return;
        }
        else if (!payload.year) {
            toast.error("Please select year");
            return;
        }

        props.history.push("/payroll/payroll-computation")
        // 
    }


    const deleteSaved = async (data) => {
        try {
            let res = await del(`/Payroll/${data?.id}`)
            if (res?.status === "success") {
                toast.success(res.message);
                getPayroll();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }



    const handleChange = (e) => {
        setPayrollData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const submitForm = async (e) => {
        let payload = {
            ...payrollData,
            mdaId: selectedMda,
            month: selectedMonth,
            year: selectedYear,
        }
        e?.preventDefault();

        console.log(payload)

        try {
            let res = await post("/payroll", payload)
            if (res?.status === "success") {
                res.success(res?.message)
            }
            else {

            }
        } catch (error) {
            toast.error(error.message)
        }
    }


    return (
        <div className="w-100">
            <div className="w-50">
                <div className="flex flex-v-center m-b-20">
                    <img src={tune} alt="" />
                    <span className="dash-text m-l-10">Monthly Payroll Initiation</span>
                </div>
                <div className="m-b-20">
                    <TextInput onChange={handleChange} value={payrollData?.title} className="pay-input" name="title" id="title" placeholder="Enter Payroll Title" />
                </div>
                <div className="m-b-20">
                    <Select
                        options={mdas}
                        onChange={(value) => setPayrollData((prev) => ({
                            ...prev,
                            mdaId: value?.value,
                            mda: value?.label
                        }))}
                        placeholder="Select MDA"
                        value={payrollData.mda && { value: payrollData.mdaId, label: payrollData.mda }}
                    />
                </div>
                <div className="flex space-between m-b-20">
                    <div className="w-75 m-r-10">
                        <Select
                            options={months}
                            onChange={(value) => setPayrollData((prev) => ({
                                ...prev,
                                month: value?.value,
                                monthName: value?.label
                            }))}
                            placeholder="Select Month"
                            value={payrollData.month && { value: payrollData.month, label: payrollData.monthName }}
                        /></div>
                    <div className="w-75">
                        <Select
                            options={years}
                            onChange={(value) => setPayrollData((prev) => ({
                                ...prev,
                                year: value?.value,
                                yearName: value?.label
                            }))}
                            placeholder="Select Year"
                            value={payrollData.year && { value: payrollData.year, label: payrollData.year }}
                        />
                    </div>
                </div>
                {/* <div className="m-b-20 flex flex-v-center">
                    <input className="bigger-checkbox m-r-10" type="checkbox" name="check" id="check" />
                    <label htmlFor="check">Upload data from previous month</label>
                </div> */}
                <div className="m-b-20">
                    <TextareaInput value={payrollData?.comment || payrollData?.initiatorsComment} onChange={handleChange} className="w-100" name="comment" id="comment" placeholder="Enter Additional Comments" />
                </div>
                <div>
                    <button onClick={validateInitiate} className="btn btn-large w-100">
                        Initiate Payroll
                    </button>
                </div>
            </div>

            <div className="w-100 m-t-80">
                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>Agency</th>
                            <th>Initiator</th>
                            <th>Payroll Title</th>
                            <th>Approval Status</th>
                            <th>Last Treated</th>
                            <th>Status</th>

                        </tr>
                    </thead>
                    <tbody className="white-bg">
                        {tableData.map((item, index) => (
                            <tr key={item?.id}>
                                <td>
                                    <div className="w-100 flex space-between">
                                        <p>{item?.mda}</p>
                                       
                                        {(item?.statusDetails === "Initiator" || item?.statusDetails === "Review") && <a target="_blank" style={{textDecoration:"none"}} href="https://treasury.greenzonetechnologies.com.ng/treasuryops/treasury_request?salary=true" className="rounded-btns">Raise Voucher</a>}

                                    </div>
                                    
                                    </td>
                                <td>{item?.initiator}</td>
                                <td>{item?.title}</td>
                                {/* <td>
                                    {getStatus(item)}
                                </td> */}

                                <td >
                                    <div className="flex flex-v-center h-100">
                                        <div
                                            className={`rounded-pill m-r-10 ${item.statusDetails === "ApprovalCompleted"
                                                ? "green-bg"
                                                : item.statusDetails === "Rejected"
                                                    ? "red-bg"
                                                    : item.statusDetails === "Pending"
                                                        ? "yellow-bg"
                                                        : "gray-bg"
                                                }`}
                                        ></div>{" "}
                                        {getStatus(item)}
                                    </div>

                                </td>


                                <td>
                                    {new Date(item?.modifiedDate).toUTCString()}
                                </td>
                                <td>
                                    {(item?.statusDetails === "Initiator" || item?.statusDetails === "Review") ? <div>

                                        <AiOutlineSend style={{ marginRight: "16px" }} size={20} color="green" className="pointer" onClick={() => {submitAlert(submitFromSaved, item, "Are You Sure You Want To Submit Payroll?" ) }} />
                                        <MdEdit style={{ marginRight: "16px" }} size={20} color="gray" className="pointer" onClick={() => { editSaved(item) }} />
                                        <AiOutlineDelete style={{ marginRight: "16px" }} size={20} color="red" className="pointer" onClick={() => { deleteAlert(deleteSaved, item, "Are You Sure You Want To Delete Payroll?") }} />
                                    </div> : "Sent"}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="m-t-10 flex flex-end">
                    <Pagination onChange={handlePagination} total={paginationLength} />
                </div>

            </div>

        </div>
    )

}

export default PayrollInitiation;
