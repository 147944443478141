/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Switch, Route } from 'react-router';


import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../components/pages/Dashboard';
import PayrollApprover from '../components/pages/PayrollApprover';
// import MdaSetup from '../components/pages/MdaSetup';
import RolesSetup from '../components/pages/RolesSetup';
import EmployeeProfile from '../components/pages/EmployeeProfile';
import EmployeeDetails from '../components/pages/EmployeeDetails';
import SupervisorOrganogram from '../components/pages/SupervisorOrganogram';
import PayrollInitiation from '../components/pages/PayrollInitiation';
import PayrollComputationSheet from '../components/pages/PayrollComputationSheet';
import SalaryTemplateManager from '../components/pages/payroll-settings/SalayTemplateManager';
import DeductionManager from '../components/pages/payroll-settings/DeductionManager';
import SalaryReviewManager from '../components/pages/payroll-settings/SalaryReviewManager';
import SeasonalItemBonus from '../components/pages/payroll-settings/SeasonalItemBonus';
import InitiateReviewRequest from '../components/pages/payroll-settings/InitiateReviewRequest';
import PayrollPreview from '../components/pages/PayrollPreview';
import InitiateAddDeduction from '../components/pages/payroll-settings/InitiateRequest/InitiateAddDeduction';
import InitiateSeasonalBonus from '../components/pages/payroll-settings/InitiateRequest/InitiateAddSeasonalBonus';
import InitiateAddSalaryTemplate from '../components/pages/payroll-settings/InitiateRequest/InitiateAddSalaryTemplate';
import ApproveBonusRequest from '../components/pages/payroll-settings/ApproveRequest/ApproveBonusRequest';
import ApproveDeductionRequest from '../components/pages/payroll-settings/ApproveRequest/ApproveDeductionRequest';
import ApproveTemplateRequest from '../components/pages/payroll-settings/ApproveRequest/ApproveTemplateRequest';


export default () => (
  <Switch>
    <ProtectedRoute
      path="/payroll/"
      exact
      component={Dashboard}
    />
     <ProtectedRoute
      path="/payroll/payroll-approver"
      exact
      component={PayrollApprover}
    />
    <ProtectedRoute
      path="/payroll/payroll-approver"
      exact
      component={PayrollApprover}
    />
    <ProtectedRoute
      path="/payroll/payroll-preview"
      exact
      component={PayrollPreview}
    />
    <ProtectedRoute
      path="/payroll/payroll-initiation"
      exact
      component={PayrollInitiation}
    />
    <ProtectedRoute
      path="/payroll/payroll-computation"
      exact
      component={PayrollComputationSheet}
    />
    <ProtectedRoute
      path="/payroll/employee-details"
      exact
      component={EmployeeDetails}
    />
    <ProtectedRoute
      path="/payroll/supervisor-organogram"
      exact
      component={SupervisorOrganogram}
    />
    <Route
      path="/payroll/settings/salary-template-manager"
      exact
      component={SalaryTemplateManager}
    />
    <Route
      path="/payroll/settings/deduction-manager"
      exact
      component={DeductionManager}
    />
    <Route
      path="/payroll/settings/salary-review-manager"
      exact
      component={SalaryReviewManager}
    />
    <Route
      path="/payroll/settings/seasonal-item"
      exact
      component={SeasonalItemBonus}
    />
    <Route
      path="/payroll/settings/salary-review-manager/approve-bonus-request/:id"
      exact
      component={ApproveBonusRequest}
    />
    <Route
      path="/payroll/settings/salary-review-manager/approve-deduction-request/:id"
      exact
      component={ApproveDeductionRequest}
    />
    <Route
      path="/payroll/settings/salary-review-manager/approve-template-request/:id"
      exact
      component={ApproveTemplateRequest}
    />
    <Route
      path="/payroll/settings/salary-review-manager/initiate-request"
      exact
      component={InitiateReviewRequest}
    />
    <Route
      path="/payroll/settings/deduction-manager/initiate-add-request/:id"
      exact
      component={InitiateAddDeduction}
    />
    <Route
      path="/payroll/settings/seasonal-item/initiate-add-request/:id"
      exact
      component={InitiateSeasonalBonus}
    />
    <Route
      path="/payroll/settings/salary-template-manager/initiate-add-request/:id"
      exact
      component={InitiateAddSalaryTemplate}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
);
